/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
//dont think bootstrap is actually being used
// @import "~bootstrap/scss/bootstrap";

// __<<ngThemingMigrationEscapedComment2>>__
// @import "~bootstrap/scss/bootstrap";

//ol styling
@import "../node_modules/ol/ol.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.main-container {
  height: 100%;
  overflow: hidden;
}
.main-mat-container {
  height: 100%;
}

.mat-drawer-container {
  position: absolute;
  top: -50px;
  margin-top: 50px;
  width: 100%;
  height: 100vh;
  padding-top: 50px;
}

.mat-drawer {
  position: absolute;
  top: 0px;
  margin-top: 50px;
}


.mat-sidenav-transition .mat-sidenav {
  /* custom animation to grow and shrink width */
  -webkit-transition: width 0.3s !important; /* For Safari 3.1 to 6.0 */
  transition: width 0.3s !important;
}
//Charts
.chartContainer canvas {
  max-height: 300px;
  width: auto;
}

.chartContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.map {
  height: 495px;
  //width: 100%;
}
.drought-map{
  height: 600px;
}
.plume-checks {
  display: flex;
}
.plume-checks-small {
  display: flex;
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 1;
  font-size: x-small;
}
.point-fire-map-div {
  font-size: x-small;
}




.ol-scale-line {
  background: rgba(0, 60, 136, 0.5);
  border-radius: 4px;
  bottom: 8px;
  right: 40px !important;
  left: auto !important;
  padding: 2px;
  position: absolute !important;
}
.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 0.8vh;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
}

/*
Generic classes?
*/

.clickable {
  cursor: pointer;
}

/*
Dashboard Card styling
*/

.dashboard-card {
  position: absolute !important;
  top: 15px !important;
  left: 15px !important;
  right: 15px !important;
  bottom: 15px !important;
  display: flex !important;
  flex-direction: column !important;
}

.dashboard-card-content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  justify-content: center;
  align-items: stretch;
}

.more-button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.grid-container {
  margin: 20px;
}

.mini-card-header {
  justify-content: center;
  font-size: 1.1vh;
}

.value-text {
  margin: 0;
}

.crop-summary-text {
  font-size: smaller !important;
}


.crop-table-cell {
  font-size: 10px !important;
}


.title-text {
  font-weight: 400;
  margin: 0;
  text-align: center;
  color: grey;
}

.avatar-icon {
  margin: 15px;
  transform: scale(2);
}

.duration {
  color: grey;
}

.difference-text {
  text-align: center;
  margin-top: 10px;
}

.card-icon {
  margin-right: 15px;
}

.green-text {
  color: green;
}

.red-text {
  color: red;
}

.center-mini-card {
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
}

.mini-card-sub-header {
  position: absolute;
  top: 0;
  margin-top: 25px;
  font-size: 3.5vh;
}

.mini-card-value {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1dcfea;
  font-size: 2.8vh;
}

.date-picker-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-button-container {
  display: flex;
  justify-content: center;
  //min-width: 50px;
  //margin-left: 25px;
}

.mini-card-label-div {
  display: flex;
}

.mini-card-label {
  font-size: 2.9vh;
  margin-right: 10px;
}
.cdk-overlay-pane {
  transform: none !important;
}

@media screen and (min-width: 500px) {
  .crop-summary-text {
    font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
}
@media screen and (min-width: 500px) {
  .side_nav_closed {
    width: 60px;
    overflow-x: hidden;
  }

  mat-sidenav:not(.mat-drawer-opened) {
    transform: translate3d(0, 0, 0) !important;
    visibility: visible !important;
    display: block !important;
    width: 60px !important;
    overflow: hidden;
  }

  mat-sidenav:not(.mat-drawer-opened) div.leftNav div.navProfile img {
    width: 40px;
    margin: 16px 0 0px 0;
  }
  mat-sidenav:not(.mat-drawer-opened) .navTitle,
  mat-sidenav:not(.mat-drawer-opened) .profileTitle {
    display: none;
  }
  .mat-drawer-content {
    margin-left: 60px;
  }
}

@media screen and (min-width: 520px) {
  .plume-checks-small {
    display: flex;
    position: absolute;
    right: 20px;
    top: 80px;
    z-index: 1;
    font-size: x-small;
  }
}

@media screen and (min-width: 600px) {
  .crop-table-cell {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1280px) {
  .map {
    height: 690px;
    //width: 100%;
  }
  .drought-map{
    height: 700px;
  }
}

@media screen and (min-width: 1458px) {
  .plume-checks-small {
    display: flex;
    position: inherit;
    margin-left: 25px;
    font-size: 14px;
  }
  .example-section {
    margin-right: 35px !important;
  }
  .point-fire-map-div {
    font-size: 14px;
  }
  .mat-checkbox {
    padding-right: 5px !important;
  }
}